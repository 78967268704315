import { log } from '@hooks/logger';
import { ErrorCode } from '@typings/ErrorCode';
import { LogLevel, Maybe } from '@typings/graphql';
import { DIS } from '@typings/dis';

export interface AppRolekeys {
  appName: string | null;
  roleKeys: string[];
}
export interface AppKeyRoledata {
  [key: string]: AppRolekeys;
}
export interface AppData {
  appData: AppKeyRoledata;
  roles: Maybe<DIS.RolesAuthenticationTicketData>[];
}

export default async function groupApplicationRolesRelationshipsMapper(
  authControllerResponse: DIS.SSOTicketResponse
): Promise<AppData> {
  const apps: AppKeyRoledata = {};
  const {
    groupApplicationRolesRelationship,
    rolesAuthenticationTicketData,
  } = authControllerResponse;
  if (!groupApplicationRolesRelationship || !rolesAuthenticationTicketData) {
    log({
      level: LogLevel.Error,
      message: ErrorCode.AuthcontrollerDataNotFound,
      other: {
        backendError:
          'Authcontroller does not contain user data, cannot proceed further.',
      },
    });
  }
  groupApplicationRolesRelationship.forEach(relationship => {
    if (!relationship?.applications) {
      return; // Skip if no applications
    }

    relationship.applications.forEach(application => {
      const appId = application?.appInstanceId;

      // Ensure the app entry exists
      if (appId && !apps[appId]) {
        apps[appId] = {
          appName: application.name || null,
          roleKeys: [],
        };
      }

      // Add roles for the current relationship to the app
      if (appId && relationship.roles) {
        relationship.roles.forEach(role => {
          const roleKey = role?.roleKey;

          // Append the roleKey if it's not already in the list
          if (roleKey && !apps[appId].roleKeys.includes(roleKey)) {
            apps[appId].roleKeys.push(roleKey);
          }
        });
      }
    });
  });
  const mappedAuthData: AppData = {
    appData: apps,
    roles: rolesAuthenticationTicketData,
  };
  log({
    level: LogLevel.Info,
    message: 'GroupApplicationRolesRelationshipsMapper Data:',
    other: {
      AppData: JSON.stringify(mappedAuthData, null, 2),
    },
  });
  return mappedAuthData;
}
